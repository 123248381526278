import React from 'react';
import {BooleanField, Datagrid, ReferenceManyField, Tab, TextField} from 'react-admin';

export const ShowUser = (props) => (
    <Tab label={"common.general"} {...props}>
        <TextField source={"name"}/>
        <TextField source={"username"}/>
        <TextField source={"email"}/>
        <BooleanField source={"verified"}/>
    </Tab>
);

export const ShowUserServices = (props) => (
    <Tab label={"resources.jobs.name"} {...props}>
        <ReferenceManyField {...props} target={props.usertype} reference={"services"} addLabel={false}>
            <Datagrid>
                <TextField source={"title"}/>
                <DateField label={"Time"}/>
                <TextField source={"type"}/>
                <TextField source={"status"}/>
            </Datagrid>
        </ReferenceManyField>
    </Tab>
);
const DateField = ({record = {}}) => (
    <span>{new Date(record.time).toDateString()}
    </span>
);