export const status = [{id: 'PENDING', name: 'service.status.pending'},
    {id: 'CANCELLED', name: 'service.status.cancelled'},
    {id: 'PROVIDER_ACCEPTED', name: 'service.status.providerAccepted'},
    {id: 'IN_PROGRESS', name: 'service.status.inProgress'},
    {id: 'COMPLETED', name: 'service.status.completed'},
    {id: 'REJECTED', name: 'service.status.rejected'}]
export const types = [{id: 'SINGLE', name: 'service.types.single'},
    {id: 'BID', name: 'service.types.bid'}]
export const movePersonStatus = [{id: 'ARRIVED', name: "service.movePerson.status.arrived"},
    {id: 'STARTED', name: "service.movePerson.status.started"},
    {id: 'FINISHED', name: "service.movePerson.status.finished"},]
export const goodsStatus = [{id: "PENDING", name: "service.goods.status.pending"},
    {id: "GOING_TO_PICK", name: "service.goods.status.goingToPick"},
    {id: "PICKED", name: "service.goods.status.picked"},
    {id: "MOVE_TO_DELIVER", name: "service.goods.status.moveToDeliver"},
    {id: "DELIVERED", name: "service.goods.status.delivered"},
    {id: "NOT_DELIVERED", name: "service.goods.status.notDelivered"},
    {id: "RETURNING", name: "service.goods.status.returning"},
    {id: "RETURNED", name: "service.goods.status.returned"},]
export const repairStatus = [{id: 'PENDING', name: 'service.repair.status.pending'},
    {id: 'MOVING', name: 'service.repair.status.moving'},
    {id: 'ARRIVED', name: 'service.repair.status.arrived'},
    {id: 'STARTED', name: 'service.repair.status.started'},
    {id: 'MOVE_TO_WORKSHOP', name: 'service.repair.status.moveToWorkshop'},
    {id: 'BILLED', name: 'service.repair.status.billed'},
    {id: 'CLIENT_BILL_ACCEPTED', name: 'service.repair.status.clientBillAccepted'},
    {id: 'FINISHED', name: 'service.repair.status.finished'},]

export const repairTypes = [{id: "ELECTRICITY", name: "service.repair.types.electricity"},
    {id: "AC", name: "service.repair.types.ac"},
    {id: "PLUMBING", name: "service.repair.types.plumbing"}]


export const StatusTranslations = {
    en: {
        service: {
            status: {
                pending: "Pending",
                cancelled: "Cancelled",
                providerAccepted: "Provider Accepted",
                inProgress: "In Progress",
                completed: "Completed",
                rejected: "Rejected",
            },
            types: {
                single: "Single",
                bid: 'Bid',
            },
            movePerson: {
                status: {
                    started: "Started",
                    arrived: "Arrived",
                    finished: "Finished"
                }
            },
            goods: {
                status: {
                    pending: "Pending",
                    goingToPick: "Going to pick",
                    picked: "Picked",
                    moveToDeliver: "Moving To Deliver",
                    delivered: "Delivered",
                    notDelivered: "Not Delivered",
                    returning: "Returning",
                    returned: "Returned",
                }
            },
            repair: {
                status: {
                    pending: "Pending",
                    moving: "Moving",
                    arrived: "Arrived",
                    started: "Started",
                    moveToWorkshop: "In workshop",
                    billed: "Billed",
                    clientBillAccepted: "Client Accepted Bill",
                    finished: "Finished"
                },
                types: {
                    ac: "Air Condition",
                    plumbing: "Plumbing",
                    electricity: "Electricity"
                }
            }
        }
    },
    ar: {
        service: {
            status: {
                pending: "Pending",
                cancelled: "Cancelled",
                providerAccepted: "Provider Accepted",
                inProgress: "In Progress",
                completed: "Completed",
                rejected: "Rejected",
            },
            types: {
                single: "Single",
                bid: 'Bid',
            },
            movePerson: {
                status: {
                    started: "Started",
                    arrived: "Arrived",
                    finished: "Finished"
                }
            },
            goods: {
                status: {
                    pending: "Pending",
                    goingToPick: "Going to pick",
                    picked: "Picked",
                    moveToDeliver: "Moving To Deliver",
                    delivered: "Delivered",
                    notDelivered: "Not Delivered",
                    returning: "Returning",
                    returned: "Returned",
                }
            },
            repair: {
                status: {
                    pending: "Pending",
                    moving: "Moving",
                    arrived: "Arrived",
                    started: "Started",
                    moveToWorkshop: "In workshop",
                    billed: "Billed",
                    clientBillAccepted: "Client Accepted Bill",
                    finished: "Finished"
                },
                types: {
                    ac: "Air Condition",
                    plumbing: "Plumbing",
                    electricity: "Electricity"
                }
            }
        }
    }
}