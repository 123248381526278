import React from 'react';
import Icon from '@material-ui/icons/Commute';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    UrlField
} from 'react-admin';
import {VehicleGrid} from "./Vehicle";

export const VehicleCompanyIcon = Icon;

export const CreateVehicleCompany = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"} validate={required()}/>
            <TextInput source={"website"}/>
            <TextInput source={"description"}/>
        </SimpleForm>
    </Create>
);
export const EditVehicleCompany = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={"list"}>
            <TextInput source={"name"} validate={required()}/>
            <TextInput source={"website"}/>
            <TextInput source={"description"}/>
        </SimpleForm>
    </Edit>
);
export const ShowVehicleCompany = (props) => (
    <Show {...props}>
        <TabbedShowLayout {...props}>
            <FormTab label={"common.general"}>
                <TextField source={"name"}/>
                <TextField source={"description"}/>
                <UrlField source={"website"}/>
            </FormTab>
            <FormTab label={"resources.vehicles.name"}>
                <ReferenceManyField reference={"vehicles"} target={"companyId"} addLabel={false}>
                    <VehicleGrid/>
                </ReferenceManyField>
            </FormTab>
        </TabbedShowLayout>
    </Show>
);
export const ListVehicleCompanies = (props) => (
    <List  {...props} hasCreate>
        <Datagrid>
            <TextField source={"name"}/>
            <UrlField source={"website"}/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);