import React from 'react';
import {Bar, BarChart, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import {translate} from 'react-admin';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from "@material-ui/core/Avatar";
import UserIcon from "@material-ui/icons/Person";

const styles = {
    card: {borderLeft: 'solid 4px #2196F3', flex: 1, margin: '1em', height: 290},
};

class UserMonthChart extends React.Component {
    state = {
        data: [],
    };

    componentDidMount() {
        let data = [{name: "Total users", value: this.props.users},
            {name: "Monthly users", value: this.props.monthlyUsers},
            {name: "Weekly users", value: this.props.weeklyUsers}
        ];
        this.setState({data: data})
    }

    componentWillReceiveProps(nextProps, context) {
        let data = [{name: "Total users", total: nextProps.users},
            {name: "Monthly users", month: nextProps.monthlyUsers},
            {name: "Weekly users", week: nextProps.weeklyUsers}
        ];
        this.setState({data: data})
    }

    render() {
        return (
            <Card style={styles.card}>
                <CardHeader
                    avatar={<Avatar>
                        <UserIcon/>
                    </Avatar>}
                    title={<h3>User statistics</h3>}
                />
                <CardContent>
                    <BarChart data={this.state.data} width={400} height={150}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="total" fill="#3F51B5"/>
                        <Bar dataKey="month" fill="#2196F3"/>
                        <Bar dataKey="week" fill="#03A9F4"/>
                    </BarChart>
                </CardContent>
            </Card>
        )
    }
}

export default translate(UserMonthChart);