import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField
} from 'react-admin'
import {movePersonStatus, status, types} from "./Status";

export const userText = user => user !== undefined && user.name !== undefined ? `${user.name} | ${user.username}` : '';
const Filters = props => (
    <Filter {...props}>
        <ReferenceInput source={"userId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'users'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <ReferenceInput source={"providerId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'movePersonProviders'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <SelectInput source={'type'}
                     alwaysOn
                     choices={types}/>
        <SelectInput source={'status'}
                     alwaysOn
                     choices={status}/>
        <SelectInput source={'rideStatus'}
                     alwaysOn
                     choices={movePersonStatus}/>
        <BooleanInput source={'sharedRide'} alwaysOn/>

    </Filter>
)

export const MovePersonServiceList = props => (
    <List {...props} sort={{field: 'requestTime', order: 'DESC'}}
          exporter={false}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <Datagrid rowClick={'show'}>
            <TextField source="id"/>
            <DateField source="requestTime" showTime/>
            <ReferenceField source="userId" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="providerId" reference="movePersonProviders">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="status"/>
            <NumberField source="price"/>
            <BooleanField source="sharedRide"/>
        </Datagrid>
    </List>
);

export const MovePersonTranslations = {
    en: {
        "movePerson/service": {
            name: "Move Person",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                sharedRide: "Shared",
            }
        },
    },
    ar: {
        "movePerson/service": {
            name: "Move Person",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                sharedRide: "Shared",
            }
        },
    },
}