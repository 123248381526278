import React from 'react';
import {ShowUser, ShowUserServices} from "./ShowUser";
import {UserFeedback} from "./UserFeedback";
import {Show, Tab, TabbedShowLayout} from 'react-admin';
import UserActions from "./UserActions";

export const ShowCustomer = (props) => (
    <Show {...props} actions={<UserActions/>}>
        <TabbedShowLayout>
            <ShowUser/>
            <Tab label={"common.reviews"}>
                <UserFeedback/>
            </Tab>
            <ShowUserServices usertype={"userId"}/>
        </TabbedShowLayout>
    </Show>
);