import React from "react";
import Icon from "@material-ui/icons/Report";
import {Datagrid, List, ReferenceField, Show, ShowButton, SimpleShowLayout, TextField} from "react-admin";
import {referenceForAuthority} from "./util/ProviderPathUtil";

export const ReportIcon = Icon;

export const ShowComplainReport = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ConditionalReferenceField from source={"fromId"}/>
            <ConditionalReferenceField source={"toId"}/>
            <ReferenceField source={"serviceId"} reference={"services"}
                            link={false}>
                <TextField source={"title"}/>
            </ReferenceField>
            <TextField source={"text"}/>

        </SimpleShowLayout>
    </Show>
);

export const ComplainReportList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <ConditionalReferenceField from source={"fromId"}/>
            <ConditionalReferenceField source={"toId"}/>

            <ReferenceField source={"serviceId"} reference={"services"}
                            link={false}>
                <TextField source={"title"}/>
            </ReferenceField>
            <ShowButton/>
        </Datagrid>
    </List>
);

const ConditionalReferenceField = ({from, record, ...rest}) => {
    if (record && record[rest.source]) {
        const reference = from ? getFromReference(record) : getToReference(record);
        return <ReferenceField {...rest}
                               link={'show'}
                               record={record}
                               source={rest.source}
                               reference={reference}>
            <TextField source="name"/>
        </ReferenceField>
    }
    return "...";
};

const getFromReference = (record) => {
    if (record === undefined || record.fromAuthority === null) {
        return "";
    }
    return referenceForAuthority(record.fromAuthority);
};

const getToReference = (record) => {
    if (record === undefined || record.toAuthority === null) {
        return ""
    }
    return referenceForAuthority(record.toAuthority);
};
