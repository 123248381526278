import React from 'react';
import PropTypes from 'prop-types';

import {Card, CardContent, CardMedia, Typography} from "@material-ui/core";

const styles = {
    card: {
        width: 300,
        margin: '1em',
    },
    media: {
        height: 300,
        width: 300
    },
};

const ImageView = (props) => (
    <Card style={styles.card}>
        <a href={props.image} target={"blank"}>
            <CardMedia style={styles.media}
                       image={props.image}
                       title={props.title}
            />
        </a>
        <CardContent>
            <Typography style={{textAlign: 'center'}} variant={"caption"}>
                {props.title}
            </Typography>
        </CardContent>
    </Card>
);
ImageView.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};
export default ImageView