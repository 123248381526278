import React, {Component} from 'react';
import englishMessages from './i18n/en';
import Layout from "./layout/Layout";
import Login from './common/Login';
import AuthClient from "./common/AuthClient";
import OurClient from "./rest/OurClient";

import {Admin, resolveBrowserLocale, Resource} from 'react-admin';
import {ListCustomers, ListTechnicians, TechnicianIcon, UserIcon} from "./users/Users";
import {ShowProvider} from "./users/ShowProvider";
import {ShowCustomer} from "./users/ShowCustomer";
import routes from "./routes";
import {SERVER_IP} from "./index";
import {
    CreateVehicleCompany,
    EditVehicleCompany,
    ListVehicleCompanies,
    ShowVehicleCompany,
    VehicleCompanyIcon
} from "./vehicle/Company";
import {CreateVehicle, EditVehicle, ListVehicle, VehicleIcon} from "./vehicle/Vehicle";
import Dashboard from "./dashboard/Dashboard";
import {EditSetting, ListSetting, SettingIcon, ShowSetting} from "./settings/Settings";
import {ShowProviderCar} from "./users/Car";
import {CityIcon, CityList, CreateCity, EditCity, ShowCity} from "./cities/City";
import {EditScreen, ListScreens} from "./translations/Translations";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import arabicMessages from "./i18n/ar";
import {ComplainReportList, ShowComplainReport} from "./ComplainReport";
import {BankAccountCreate, BankAccountEdit, BankAccountList, BankAccountShow} from "./accounts/BankAccount";
import {ServiceReceiptList} from "./accounts/ServiceReceipt";
import {CommissionReceiptCreate, CommissionReceiptList, ShowCommissionReceipt} from "./accounts/Payments";
import {ContactUsList} from "./users/ContactUs";
import {NotificationList} from "./users/Notification";
import {MovePersonServiceList} from "./services/MovePersonService";
import {GoodsServicesList} from "./services/GoodsServices";
import {RepairServicesList} from "./services/RepairServies";

const messages = {
    en: {...englishMessages},
    ar: {...arabicMessages}
};

export const locale = () => {
    let loc = resolveBrowserLocale();
    if (loc !== 'ar' && loc !== 'en') {
        return 'ar';
    } else {
        return loc;
    }
};

const i18nProvider = polyglotI18nProvider(() => messages[locale()], 'en');

class App extends Component {
    render() {
        return (
            <Admin title={"Rasan Admin"}
                   i18nProvider={i18nProvider}
                   loginPage={Login}
                   authProvider={AuthClient}
                   layout={Layout}
                   dashboard={Dashboard}
                   customRoutes={routes}
                   dataProvider={OurClient(SERVER_IP)}
            >

                <Resource
                    icon={VehicleCompanyIcon}
                    list={ListVehicleCompanies}
                    show={ShowVehicleCompany}
                    edit={EditVehicleCompany}
                    create={CreateVehicleCompany}
                    name={"vehicles/companies"}
                />
                <Resource
                    icon={VehicleIcon}
                    list={ListVehicle}
                    edit={EditVehicle}
                    create={CreateVehicle}
                    name={"vehicles"}
                />
                <Resource
                    name={'cities'}
                    icon={CityIcon}
                    list={CityList}
                    create={CreateCity}
                    edit={EditCity}
                    show={ShowCity}/>

                <Resource icon={UserIcon}
                          list={ListCustomers}
                          show={ShowCustomer}
                          name={"users"}
                />

                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"movePersonProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"goodsProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"groceryProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"foodProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"waterProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"gasProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"repairProviders"}
                          show={ShowProvider}
                />
                <Resource icon={TechnicianIcon}
                          list={ListTechnicians}
                          name={"charityProviders"}
                          show={ShowProvider}/>

                <Resource icon={SettingIcon}
                          list={ListSetting}
                          name={"settings"}
                          show={ShowSetting}
                          edit={EditSetting}
                />
                <Resource name={"screens"}
                          edit={EditScreen}
                          list={ListScreens}/>
                <Resource name={"services"}/>
                <Resource name={"complainReports"}
                          list={ComplainReportList}
                          show={ShowComplainReport}/>
                <Resource name={"feedback"}/>
                <Resource name={"cars"}
                          show={ShowProviderCar}
                />
                <Resource name={'accounts'}
                          list={BankAccountList}
                          create={BankAccountCreate}
                          edit={BankAccountEdit}
                          show={BankAccountShow}/>
                <Resource name={"serviceReceipts"}
                          list={ServiceReceiptList}/>
                <Resource name={'commissionReceipts'}
                          show={ShowCommissionReceipt}
                          create={CommissionReceiptCreate}
                          list={CommissionReceiptList}/>
                <Resource name={'contactUs'}
                          list={ContactUsList}/>
                <Resource list={NotificationList}
                          name={'messages'}/>
                <Resource name={'movePerson/service'}
                          list={MovePersonServiceList}/>
                <Resource name={'goods/service'}
                          list={GoodsServicesList}/>
                <Resource name={'repair/service'}
                          list={RepairServicesList}/>

            </Admin>
        );
    }
}

export default App;
