import React from 'react';
import Icon from '@material-ui/icons/Message';
import {translate} from 'react-admin';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from "@material-ui/core/Avatar";
import {SERVER_IP} from "../index";

const styles = {
    card: {borderLeft: 'solid 4px #4caf50', flex: 1, marginLeft: '1em', height: 270},
    icon: {float: 'right', width: 64, height: 64, padding: 16, color: '#009688'},
    progress: {marginLeft: "1em"}
};

class NotificationCard extends React.Component {
    state = {
        loading: false,
        title: null,
        body: null,
        error: false,
        color: "#FFF",
        disabled: false
    };
    handleSend = () => {
        if (this.state.title == null || this.state.body === null) {
            this.setState({error: true})
        } else if (this.state.title.length === 0 || this.state.body === 0) {
            this.setState({error: true})
        } else {
            this.setState({
                loading: true,
                color: "#9C27B0", disabled: true
            });
            this.send(this.state.title, this.state.body);
        }

    };
    send = (title, body) => {
        let url = `${SERVER_IP}/messages/send`;
        const token = localStorage.getItem('token');
        if (token == null) {
            Promise.reject("No Auth");
            return;
        }
        const requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);
        requestHeaders.append("Access-Control-Allow-Origin", "*");
        requestHeaders.set('Content-Type', 'application/json');
        let options = {};
        options.method = "POST";
        let body1 = {
            subject: title,
            body: body,
            time: Date.now()
        };
        options.body = JSON.stringify(body1);
        return fetch(url, {...options, headers: requestHeaders})
            .then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            )
            .then(({status, statusText, headers, body}) => {
                try {
                    this.setState({
                        loading: false,
                        color: "#FFF",
                        disabled: false,
                        title: "",
                        body: ""
                    });
                    this.props.callback();
                } catch (e) {
                }
                if (status < 200 || status >= 300) {
                    return Promise.reject("unknown");
                }
            });
    };

    onTitleChange = (event) => {
        this.setState({error: false,title:event.target.value});
    };
    onBodyChange = (event) => {
        this.setState({error: false,body:event.target.value});

    };

    render() {
        return (
            <div>
                <div style={styles.progress}>
                    {this.state.loading ?
                        <LinearProgress
                            color={"secondary"}
                        />
                        : null
                    }
                </div>
                <Card style={styles.card}>
                    <CardHeader avatar={<Avatar>
                        <Icon/>
                    </Avatar>}
                                title={<h3>Notification</h3>}/>
                    <CardContent>
                        <TextField placeholder={"Title"}
                                   label={"Title"}
                                   value={this.state.title}
                                   onChange={this.onTitleChange.bind(this)}/>
                        <TextField fullWidth placeholder={"Message"}
                                   label={"Message"}
                                   value={this.state.body}
                                   onChange={this.onBodyChange.bind(this)}/>
                        <br/>
                        <br/>
                        <Button variant={"raised"}
                                color={"secondary"}
                                onClick={this.handleSend}
                                disabled={this.state.disabled}
                        >
                            SEND
                        </Button>
                    </CardContent>
                </Card>

            </div>
        )
    }
}

export default translate(NotificationCard);
