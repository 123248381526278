import React from 'react';
import {
    ChipField,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import Icon from '@material-ui/icons/DirectionsCar';
import Chip from "@material-ui/core/Chip";

export const VehicleIcon = Icon;

const colors = [
    {id: "BLUE", name: "Blue"},
    {id: "GREEN", name: "Green"},
    {id: "RED", name: "Red"},
    {id: "ORANGE", name: "Orange"},
    {id: "WHITE", name: "White"},
    {id: "BROWN", name: "Brown"},
    {id: "YELLOW", name: "Yellow"},
    {id: "BLACK", name: "Black"}

];

export const CreateVehicle = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"} validate={required()}/>
            <ReferenceInput reference={"vehicles/companies"} source={"companyId"} validate={required()}>
                <SelectInput optionText={"name"}/>
            </ReferenceInput>
            <SelectArrayInput source={"colors"} choices={colors} validate={required()}/>
            <NumberInput source={'capacity'} validate={required()}/>
        </SimpleForm>
    </Create>
);
export const EditVehicle = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"} validate={required()}/>
            <ReferenceInput reference={"vehicles/companies"} source={"companyId"} validate={required()}>
                <SelectInput optionText={"name"}/>
            </ReferenceInput>
            <SelectArrayInput source={"colors"} choices={colors} validate={required()}/>
            <NumberInput source={'capacity'} validate={required()}/>
        </SimpleForm>
    </Edit>
);

export const ListVehicle = (props) => (
    <List {...props}>
        <VehicleGrid/>
    </List>
);

export const VehicleGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"name"}/>
        <ReferenceField source={"companyId"} link={'show'} reference={"vehicles/companies"}>
            <ChipField source={"name"}/>
        </ReferenceField>
        <TextField source={'capacity'}/>
        <ColorField label={"Colors"}/>
        <EditButton/>
        <DeleteButton/>
    </Datagrid>
);

const ColorField = ({record}) => (
    <div>
        {record.colors.map(item => (
            <Chip key={item} label={item}/>
        ))}
    </div>
);
