import React from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {goodsStatus, status, types} from "./Status";

export const userText = user => user !== undefined && user.name !== undefined ? `${user.name} | ${user.username}` : '';
const Filters = props => (
    <Filter {...props}>
        <ReferenceInput source={"userId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'users'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <ReferenceInput source={"providerId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'goodsProviders'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <SelectInput source={'type'}
                     alwaysOn
                     choices={types}/>
        <SelectInput source={'status'}
                     alwaysOn
                     choices={status}/>
        <SelectInput source={'deliveryStatus'}
                     alwaysOn
                     choices={goodsStatus}/>
        <TextInput source={'deliveryCode'} alwaysOn/>

    </Filter>
)

export const GoodsServicesList = props => (
    <List {...props} sort={{field: 'requestTime', order: 'DESC'}}
          exporter={false}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
            <DateField source="requestTime"/>
            <ReferenceField source="userId" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="providerId" reference="goodsProviders">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="status"/>
            <NumberField source="price"/>
            <NumberField source="noOfPackages"/>
            <NumberField source="packageWeight"/>
            <TextField source="receiverName"/>
            <TextField source="deliveryTitle"/>
            <TextField source="receiverNumber"/>
            <TextField source="deliveryCode"/>
            <TextField source="scannedCode"/>
        </Datagrid>
    </List>
);

export const GoodsDeliveryTranslations = {
    en: {
        "goods/service": {
            name: "Goods Delivery",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                noOfPackages: "# Packages",
                packageWeight: "Weight",
                receiverName: "Receiver Name",
                deliveryTitle: "Delivery Title",
                receiverNumber: "Receiver Number",
                deliveryCode: "Delivery Code",
                scannedCode: "Barcode",
            }
        },
    },
    ar: {
        "goods/service": {
            name: "Goods Delivery",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                noOfPackages: "# Packages",
                packageWeight: "Weight",
                receiverName: "Receiver Name",
                deliveryTitle: "Delivery Title",
                receiverNumber: "Receiver Number",
                deliveryCode: "Delivery Code",
                scannedCode: "Barcode",
            }
        },
    },
}