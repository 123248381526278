import React from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    NumberField,
    NumberInput,
    required,
    RichTextField,
    Show,
    ShowButton,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

import InfoIcon from '@material-ui/icons/Settings'

export const SettingIcon = InfoIcon;

let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],
    [{'direction': 'rtl'}],

    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],
];

export const EditSetting = (props) => (
    <Edit {...props} hasDelete={false}>
        <TabbedForm redirect={'list'}>
            <FormTab label={"resources.settings.fields.agreement.movePerson"}>
                <RichTextInput source="agreement.movePerson" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.goodsDelivery"}>
                <RichTextInput source="agreement.goodsDelivery" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.food"}>
                <RichTextInput source="agreement.food" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.grocery"}>
                <RichTextInput source="agreement.grocery" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.charity"}>
                <RichTextInput source="agreement.charity" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.water"}>
                <RichTextInput source="agreement.water" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.mechanic"}>
                <RichTextInput source="agreement.mechanic" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>
            <FormTab  label={"resources.settings.fields.agreement.gas"}>
                <RichTextInput source="agreement.gas" toolbar={toolbarOptions} validate={required()}/>
            </FormTab>

            <FormTab label={"resources.settings.commissionName"}>
                <NumberInput source={"commission.movePerson"} validate={required()}/>
                <NumberInput source={"commission.goodsDelivery"} validate={required()}/>
                <NumberInput source={"commission.food"} validate={required()}/>
                <NumberInput source={"commission.grocery"} validate={required()}/>
                <NumberInput source={"commission.charity"} validate={required()}/>
                <NumberInput source={"commission.water"} validate={required()}/>
                <NumberInput source={"commission.mechanic"} validate={required()}/>
                <NumberInput source={"commission.gas"} validate={required()}/>
            </FormTab>
        </TabbedForm>
    </Edit>
);
export const ListSetting = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source={"id"}/>
            <EditButton/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const ShowSetting = (props) => (
    <Show {...props} title={"resources.settings.name"} hasDelete={false}>
        <TabbedShowLayout>
            <Tab label={"resources.settings.agreement"}>
                <RichTextField source={"agreement.movePerson"}/>
                <RichTextField source={"agreement.goodsDelivery"}/>
                <RichTextField source={"agreement.food"}/>
                <RichTextField source={"agreement.grocery"}/>
                <RichTextField source={"agreement.charity"}/>
                <RichTextField source={"agreement.water"}/>
                <RichTextField source={"agreement.mechanic"}/>
                <RichTextField source={"agreement.gas"}/>
            </Tab>
            <Tab label={"resources.settings.commissionName"}>
                <NumberField source={"commission.movePerson"}/>
                <NumberField source={"commission.goodsDelivery"}/>
                <NumberField source={"commission.food"}/>
                <NumberField source={"commission.grocery"}/>
                <NumberField source={"commission.charity"}/>
                <NumberField source={"commission.water"}/>
                <NumberField source={"commission.mechanic"}/>
                <NumberField source={"commission.gas"}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
