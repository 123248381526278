import React from "react";
import {List, Datagrid, TextField, DeleteWithConfirmButton} from 'react-admin';

export const ContactUsList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source={'name'}/>
            <TextField source={'email'}/>
            <TextField source={'phone'}/>
            <TextField source={'message'}/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);

export const ContactUsTranslations = {
    en: {
        contactUs: {
            name: 'Feedbacks',
            fields: {
                name: 'Name',
                email: "Email",
                phone: "Phone",
                message: "Message",
            }
        }
    },
    ar: {
        contactUs: {
            name: 'Feedbacks',
            fields: {
                name: 'Name',
                email: "Email",
                phone: "Phone",
                message: "Message",
            }
        }
    }
}