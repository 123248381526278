import React from "react";
import SendIcon from '@material-ui/icons/Notifications';
import {Button, createMuiTheme, DialogActions, MuiThemeProvider, Snackbar} from "@material-ui/core";
import {translate} from 'react-admin';
import SendNotificationView from "./SendNotificationView";
import {fixedPath} from "../rest/OurClient";
import {lightTheme} from "../layout/Layout";

class UserShowActions extends React.Component {
    state = {open: false};

    render() {
        const {data, resource, translate} = this.props;
        const {showMessage, message, open} = this.state;
        return (
            <DialogActions>
                {data !== undefined && <Button variant={'text'}
                                               color={'primary'}
                                               onClick={() => this.setState({open: true})}>
                    <SendIcon/> {translate("users.message.sendMessage")}
                </Button>}
                <SendNotificationView open={open}
                                      basePath={fixedPath(resource)}
                                      onResponse={(message, open) => {
                                          this.setState({showMessage: message !== null, message: message, open: open})
                                      }}
                                      record={data}/>
                <Snackbar open={showMessage}
                          message={message}
                          autoHideDuration={5000}
                          onClose={() => {
                              this.setState({showMessage: false})
                          }}/>
            </DialogActions>
        );
    }
}

const Themed = props =>
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <UserShowActions {...props}/>
    </MuiThemeProvider>;
export default translate(Themed);