import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {translate} from 'react-admin';

class ApproveRejectView extends React.Component {
    state = {};

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false});
    };


    approveOrReject = () => {
        const {record, status} = this.props;
        if (record === undefined || status === undefined) {
            return
        }
        this.setState({loading: true});

        fetchJson(`${SERVER_IP}/payments/updateStatus/${record.id}?&status=${status}`,
            {method: "POST"})
            .then(() => {
                this.setState({loading: false, status: ""});
                this.props.onResponse(this.props.translate(status === "APPROVED" ? "payments.approveSuccess" : "payments.rejectSuccess"), false, status);
            }, () => {
                this.setState({loading: false});
                this.props.onResponse(this.props.translate("payments.updateFailed"), false, undefined);
            })

    };

    render() {
        const {loading} = this.state;
        const {translate, open, status} = this.props;
        const messages = {};
        if (status === "APPROVED") {
            messages.title = "payments.approveTitle";
            messages.body = "payments.approveMessage";
        } else {
            messages.title = "payments.rejectTitle";
            messages.body = "payments.rejectMessage";
        }
        return (
            <Dialog open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate(messages.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate(messages.body)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                this.props.onResponse(null, false);
                            }}>
                        {translate("payments.no")}
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={this.approveOrReject}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        {translate("payments.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default translate(ApproveRejectView);