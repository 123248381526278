import React from 'react';
import Rating from 'react-rating';
import {Datagrid, ReferenceField, ReferenceManyField, TextField} from 'react-admin';
import EmptyRateImage from '../images/star_empty.png';
import RateImage from '../images/star_filled.png';

export const UserFeedback = (props) => (
    <ReferenceManyField reference={"feedback"} target={"toId"} addLabel={false} {...props}>
        <Datagrid>
            <ReferenceField source={"serviceId"} reference={"services"}>
                <TextField source={"title"}/>
            </ReferenceField>
            <ReferenceField source={"fromId"} link={'show'} reference={"users"}>
                <TextField source={"name"}/>
            </ReferenceField>
            <RatingView {...props} source={"rate"}/>
        </Datagrid>
    </ReferenceManyField>
);
export const RatingView = (props) => (
    <Rating initialRating={props.record.rate}
            emptySymbol={<img src={EmptyRateImage} width={"20"} height={"20"} alt={"Empty rate"}/>}
            fullSymbol={<img src={RateImage} width={"20"} height={"20"} alt={"Rate"}/>}

            readonly={true}/>
);