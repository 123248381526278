import React from "react";
import ApproveIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import {Button, createMuiTheme, DialogActions, MuiThemeProvider, Snackbar} from "@material-ui/core";
import {translate} from 'react-admin';
import {lightTheme} from "../layout/Layout";
import ApproveRejectView from "./ApproveRejectView";

class PaymentActions extends React.Component {
    state = {open: false};

    render() {
        const {data, translate} = this.props;
        const {showMessage, message, open, status} = this.state;

        return (
            <DialogActions>
                {data !== undefined && data.status === "PENDING" && status === undefined && <Button variant={'text'}
                                                                                                    color={'secondary'}
                                                                                                    onClick={() => this.setState({
                                                                                                        open: true,
                                                                                                        status: "APPROVED"
                                                                                                    })}>
                    <ApproveIcon/> {translate("payments.approve")}
                </Button>}
                {data !== undefined && data.status === "PENDING" && status === undefined && <Button variant={'text'}
                                                                                                    color={'primary'}
                                                                                                    onClick={() => this.setState({
                                                                                                        open: true,
                                                                                                        status: "REJECTED"
                                                                                                    })}>
                    <CloseIcon/> {translate("payments.reject")}
                </Button>}
                {!open && data !== undefined && (data.status === "REJECTED" || status === "REJECTED") && <Button
                    variant={'text'}
                    color={'primary'}>
                    {translate("resources.commissionReceipts.status.rejected")}
                </Button>}
                {!open && data !== undefined && (data.status === "APPROVED" || status === "APPROVED") && <Button
                    variant={'text'}
                    color={'primary'}>
                    {translate("resources.commissionReceipts.status.approved")}
                </Button>}
                <ApproveRejectView open={open}
                                   status={status}
                                   onResponse={(message, open, st) => {
                                       this.setState({
                                           showMessage: message !== null,
                                           message: message,
                                           open: open,
                                           status: st
                                       })
                                   }}
                                   record={data}/>
                <Snackbar open={showMessage}
                          message={message}
                          autoHideDuration={5000}
                          onClose={() => {
                              this.setState({showMessage: false})
                          }}/>
            </DialogActions>
        );
    }
}

export const PaymentActionTranslations = {
    en: {
        payments: {
            approve: "Approve",
            reject: "Reject",
            approveSuccess: "Payment approved!",
            rejectSuccess: "Payment rejected",
            updateField: "Failed to update payment status",
            approveTitle: "Approve Payment",
            approveMessage: "Do you really want to approve this payment. This action cannot be undone",
            rejectTitle: "Reject Payment",
            rejectMessage: "Do you really want to reject this payment. This action cannot be undone",
            yes: "Yes",
            no: "No",
        },
    },
    ar: {
        payments: {
            approve: "Approve",
            reject: "Reject",
            approveSuccess: "Payment approved!",
            rejectSuccess: "Payment rejected",
            updateField: "Failed to update payment status",
            approveTitle: "Approve Payment",
            approveMessage: "Do you really want to approve this payment. This action cannot be undone",
            rejectTitle: "Reject Payment",
            rejectMessage: "Do you really want to reject this payment. This action cannot be undone",
            yes: "Yes",
            no: "No",
        },
    }
};
const Themed = props =>
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <PaymentActions {...props}/>
    </MuiThemeProvider>;
export default translate(Themed);