import React from 'react';
import Icon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';

import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DeleteButton,
    Filter,
    List,
    ShowButton,
    TextField,
    TextInput
} from 'react-admin';

export const UserIcon = Icon;
export const TechnicianIcon = BuildIcon;


const UserFilters = (props) => (
    <Filter {...props}>
        <TextInput source={"name"} alwaysOn/>
        <TextInput source={"email"} alwaysOn/>
        <TextInput source={"username"}/>
        <BooleanInput source={"active"}/>
    </Filter>
);
export const ListCustomers = (props) => (
    <List {...props} bulkActionButtons={false} filters={<UserFilters/>}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <TextField source={"email"}/>
            <BooleanField source={"active"}/>
            <ShowButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const ListTechnicians = (props) => (
    <List {...props} bulkActionButtons={false} filters={<UserFilters/>}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <TextField source={"email"}/>
            <BooleanField source={"active"}/>
            <ShowButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);
