import {AutocompleteInput, ReferenceField, ReferenceInput, TextField} from "react-admin";
import React from "react";

const movePerson = "movePersonProviders";
const users = "users";
const gasProviders = "gasProviders";
const foodProviders = "foodProviders";
const waterProviders = "waterProviders";
const repairProviders = "repairProviders";
const charityProviders = "charityProviders";
const groceryProviders = "groceryProviders";
const goodsProviders = "goodsProviders";

export const referenceForAuthority = (authority) => {
    switch (authority) {
        case "ROLE_USER":
            return users;
        case "ROLE_GAS_PROVIDER":
            return gasProviders;
        case "ROLE_FOOD_PROVIDER":
            return foodProviders;
        case "ROLE_WATER_PROVIDER":
            return waterProviders;
        case "ROLE_REPAIR_PROVIDER":
            return repairProviders;
        case "ROLE_CHARITY_PROVIDER":
            return charityProviders;
        case "ROLE_GROCERY_PROVIDER":
            return groceryProviders;
        case "ROLE_MOVE_PROVIDER":
            return movePerson;
        case "ROLE_GOODS_PROVIDER":
            return goodsProviders;
        default:
            return "";
    }
};

export const referenceForServiceType = serviceType => {
    //MOVE_PERSON, GOODS_DELIVERY, FOOD, GROCERY, CHARITY, WATER, REPAIR, GAS
    switch (serviceType) {
        case "MOVE_PERSON":
            return movePerson;
        case "GOODS_DELIVERY":
            return goodsProviders;
        case "FOOD":
            return foodProviders;
        case "GROCERY":
            return groceryProviders;
        case "CHARITY":
            return charityProviders;
        case "WATER":
            return waterProviders;
        case "REPAIR":
            return repairProviders;
        case "GAS":
            return gasProviders;
        default:
            return "";
    }
};

export const ProviderServiceReferenceField = ({fieldName, record, ...rest}) => {
    if (record && record[rest.source]) {
        const reference = referenceForServiceType(record[fieldName]);
        return <ReferenceField {...rest}
                               link={'show'}
                               record={record}
                               source={rest.source}
                               reference={reference}>
            <TextField source="name"/>
        </ReferenceField>
    }
    return "...";
};

export const ProviderReferenceInput = ({serviceType, validate, source, ...rest}) => {
    const reference = referenceForServiceType(serviceType);
    if (reference === undefined || reference.length < 3) {
        return "";
    }
    return <ReferenceInput {...rest}
                           source={source}
                           validate={validate}
                           filterToQuery={searchText => ({username: searchText})}
                           reference={reference}>
        <AutocompleteInput
            optionText={provider => provider !== null ? `${provider.name}/${provider.username}` : ""}/>
    </ReferenceInput>

};


export const serviceTypes = [
    {id: "MOVE_PERSON", en: "Move Person", ar: "توصيل أشخاص"},
    {id: "GOODS_DELIVERY", en: "Goods Delivery", ar: "توصيل طرود"},
    {id: "FOOD", en: "Food", ar: "مطاعم"},
    {id: "GROCERY", en: "Grocery", ar: "مواد غذائية"},
    {id: "CHARITY", en: "Charity", ar: "الجمعيات الخيرية"},
    {id: "WATER", en: "Water", ar: "مياه"},
    {id: "REPAIR", en: "Repair", ar: " صيانة منزلية"},
    {id: "GAS", en: "Gas", ar: "غاز"},
];