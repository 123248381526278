import React from 'react';
import TextField from "@material-ui/core/TextField";
import {translate} from 'react-admin';
import {List} from "@material-ui/core";


const styles = {
    wrapper: {
        position: 'relative',
        textAlign: 'start',
    },
    tDiv: {
        padding: '2em'
    },
    keyDiv: {
        textAlign: 'center',
    },
    keySpan: {
        fontSize: '18px',
        padding: '10px',
    },
    original: {
        fontSize: '12px',
        color: '#888888',
        fontWeight:'light'
    },
    field: {
        margin: '1em',
    }
};


class TranslationTable extends React.Component {
    state = {
        rows: [],
        open: false,
        loading: false,
        rowsPerPage: 7,
        page: 0
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    componentDidMount() {
        let rows = this.props.input.value;
        if (!Array.isArray(rows)) {
            rows = [];
        }
        if (rows.length === 0) {
            rows.push({});
        }
        this.props.input.onChange(rows);
        this.setState({rows: rows});
    }


    onChange = (name, value, index) => {
        let data = this.state.rows;
        data[index][name] = value;
        this.setState({rows: data});
        this.props.input.onChange(data)
    };

    addRow = () => {
        let rows = this.state.rows;
        rows.push({});
        this.setState({rows: rows})
    };
    deleteRow = (index) => {
        let rows = this.state.rows;
        if (index < rows.length && index > -1) {
            rows.splice(index, 1)
        }
        this.setState({rows: rows});
        this.props.input.onChange(rows);
    };


    render() {
        const {rows, rowsPerPage, page} = this.state;
        const {translate} = this.props;

        return (
            <div>
                <div style={{
                    marginBottom: '1em', height: '700px',
                    scrollbarWidth: 'thin',
                    overflow: 'scroll'
                }}>
                    <List>
                        {rows.map((n, index) => (
                            <div style={styles.tDiv}>
                                <div style={styles.keyDiv}><span style={styles.keySpan}>{n.original}</span></div>
                                <div style={styles.keyDiv}><span style={styles.original}>{n.key}</span></div>
                                <TextField value={n.english == null ? "" : n.english}
                                           fullWidth
                                           multiline
                                           variant={'filled'}
                                           style={styles.field}
                                           label={translate("resources.screens.english")}
                                           onChange={(e) => {
                                               this.onChange("english", e.target.value, index)
                                           }}/>
                                <TextField value={n.arabic == null ? "" : n.arabic}
                                           fullWidth
                                           multiline
                                           variant={'filled'}
                                           style={styles.field}
                                           label={translate("resources.screens.arabic")}
                                           onChange={(e) => {
                                               this.onChange("arabic", e.target.value, index + (page * rowsPerPage))
                                           }}/>
                            </div>
                        ))}
                    </List>
                </div>
                {/*<div style={styles.wrapper}>*/}
                {/*    <Button variant={'contained'} color={'primary'} size={'large'}*/}
                {/*            onClick={this.addRow}>*/}
                {/*        {translate("resources.screens.add")}*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default translate(TranslationTable);