import englishMessages from 'ra-language-english';
import {BankAccountTranslations} from "../accounts/BankAccount";
import {UserMessageTranslations} from "../users/SendNotificationView";
import {ServiceReceiptTranslations} from "../accounts/ServiceReceipt";
import {CommissionReceiptTranslations} from "../accounts/Payments";
import {PaymentActionTranslations} from "../accounts/PaymentActions";
import {ContactUsTranslations} from "../users/ContactUs";
import {NotificationTranslations} from "../users/Notification";
import {MovePersonTranslations} from "../services/MovePersonService";
import {GoodsDeliveryTranslations} from "../services/GoodsServices";
import {RepairTranslations} from "../services/RepairServies";
import {StatusTranslations} from "../services/Status";

const commonProviders = {
    name: "Name",
    email: "Email",
    username: "Phone Number",
    commission: 'Commission',
    active: "Can user login?",
};
export default {
    ...englishMessages,
    pos: {
        uploadImage: {
            uploading: "Uploading image",
            drop: "Select by click or drop image file here!",
            remove: "Remove",
        }
    },
    dashboard: {},
    resources: {
        ...MovePersonTranslations.en,
        ...GoodsDeliveryTranslations.en,
        ...RepairTranslations.en,
        cities: {
            name: "Cities",
            fields: {
                name: "City Name",
                description: "About",
                area: "Area (Square KM)"
            },
        },

        screens: {
            name: "Screens",
            english: "English",
            arabic: "Arabic",
            save: "Save",
            add: "Add",
            key: "Key",
            original: "Original",
            urdu: "Urdu",
            turkish: "Turkish",
            delete: "Delete",
            saved: "Successfully saved",
            appTranslations: "Mobile App Translations",
            adminTranslations: "Admin Panel Translations",
        },
        users: {
            name: "Customers",
            messages: "Messages",
            fields: {
                name: "Name",
                username: "Phone Number",
                enabled: "Can user login?",
                email: "Email",
                active: "Active",
            },
        },

        movePersonProviders: {
            name: "Move Person Providers",
            fields: {
                ...commonProviders
            },
        },
        goodsProviders: {
            name: "Goods Providers",
            fields: {
                ...commonProviders
            },
        },
        groceryProviders: {
            name: "Grocery Providers",
            fields: {
                ...commonProviders
            },
        },
        foodProviders: {
            name: "Food Providers",
            fields: {
                ...commonProviders
            },
        },
        gasProviders: {
            name: "Gas Providers",
            fields: {
                ...commonProviders
            },
        },
        waterProviders: {
            name: "Water Providers",
            fields: {
                ...commonProviders
            },
        },
        repairProviders: {
            name: "Repair Providers",
            fields: {
                ...commonProviders
            },
        },
        charityProviders: {
            name: "Charity Providers",
            fields: {
                ...commonProviders
            },
        },
        vehicles: {
            name: "Vehicles",
            fields: {
                name: "Vehicle Name",
                companyId: "Company",
                colors: "Available Colors",
            }
        },
        cars: {
            fields: {
                number: "Plate",
                model: "Model",
                color: "Color",
            }
        },

        jobs: {
            name: "Services",
            fields: {
                title: "Title",
                type: "Type",
                status: "Status",
            }
        },

        settings: {
            name: "Settings",
            commissionName: "Commission",
            agreement: "Agreements",
            fields: {
                commission: {
                    movePerson: "Move Person",
                    goodsDelivery: "Goods Delivery",
                    food: "Food",
                    grocery: "Grocery",
                    charity: "Charity",
                    water: "Water",
                    mechanic: "Mechanic",
                    gas: "Gas",
                    userAgreement: "User Agreement",
                },
                agreement: {
                    movePerson: "Move Person",
                    goodsDelivery: "Goods Delivery",
                    food: "Food",
                    grocery: "Grocery",
                    charity: "Charity",
                    water: "Water",
                    mechanic: "Mechanic",
                    gas: "Gas",
                }
            },

        },

        "vehicles/companies": {
            name: "Vehicle Companies",
            fields: {
                name: "Company name",
                website: "Company website",
                description: "Description",
            }
        },
        feedback: {
            name: "Reviews",
            fields: {
                serviceId: "Service",
                from: "From",
                rate: "Rating",
            }
        },
        complainReports: {
            name: "Reports",
            fields: {
                fromId: "From",
                toId: "To",
                serviceId: "Related service",
                text: "Text",
            }
        },
        ...BankAccountTranslations.en,
        ...ServiceReceiptTranslations.en,
        ...CommissionReceiptTranslations.en,
        ...ContactUsTranslations.en,
        ...NotificationTranslations.en,
    },
    common: {
        general: "General",
        location: "Location",
        reviews: "Reviews",
        documents: "Documents",
        menuProviders: "Providers",
        menuVehicles: "Vehicles",
        accounts: "Account",
        services: "Services",
    },
    documents: {
        driverNumber: "Driver number",
        userVerified: "User verified",
        verify: "Verify",
    },
    ...UserMessageTranslations.en,
    ...PaymentActionTranslations.en,
    ...StatusTranslations.en,

}