import React from 'react'
import Icon from '@material-ui/icons/Translate';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    required,
    SaveButton,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    Filter
} from 'react-admin';
import {Field} from 'react-final-form';
import ImageUpload from "../common/ImageUpload";
import TranslationTable from "./TranslationTable";
import Typography from "@material-ui/core/Typography";

export const TranslationIcon = Icon;
const Filters = props => (
    <Filter {...props}>
        <TextInput source={'title'} alwaysOn/>
        <TextInput source={'name'} alwaysOn/>
        <TextInput source={'q'} alwaysOn/>
    </Filter>
);
export const ListScreens = (props) => (
    <List {...props}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={"title"}/>
            <EditButton/>
        </Datagrid>
    </List>
);

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const CreateScreen = (props) => (
    <Create {...props}>
        <TabbedForm redirect={'list'}>
            <FormTab label={"General"}>
                <TextInput source={"name"} validate={required()}/>
                <TextInput source={'title'} validate={required()}/>
            </FormTab>
            <FormTab label={"Image"}>
                <Field name={"image"}
                       source={"image"}
                       component={ImageUpload}/>
            </FormTab>
            <FormTab label={"Strings"}>
                <Field name={"translations"}
                       source={"translations"}
                       component={TranslationTable}/>
            </FormTab>
        </TabbedForm>

    </Create>
);
export const EditScreen = (props) => (
    <Edit {...props} aside={<Aside/>}>
        <SimpleForm toolbar={<PostEditToolbar/>}>
            <Field name={"translations"}
                   source={"translations"}
                   component={TranslationTable}/>
        </SimpleForm>
    </Edit>
);

const Aside = ({record}) => (
    <div style={{width: 300,height:'400', margin: '1em'}}>
        <Typography variant="h6">Screen</Typography>
        {record && (
            <img alt="screenshot" src={record.image} height={'700'} width={'350'}/>
        )}
    </div>
);
