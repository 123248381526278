import React from 'react';
import AdminMessage from "./AdminMessage";
import MessagesList from "./MessagesList";
import UserChart from "./UserPlatformChart";
import UserMonthChart from "./UserMonthChart";
import {SERVER_IP} from "../index";

const styles = {
    flex: {display: 'flex', marginBottom: '2em'},
    leftCol: {flex: 1, margin: '1em'},
    rightCol: {flex: 1, margin: '1em'},
    singleCol: {marginTop: '2em'},
    chartStyle: {margin: '1em', borderLeft: 'solid 4px #E91E63', flex: '1',}
};

export default class Dashboard extends React.Component {
    state = {
        notifications: [],
        data: {},
    };
    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.loadMessages();
        this.loadDashboard();
    };

    loadDashboard = () => {
        let url = `${SERVER_IP}/dashboard/load`;
        const token = localStorage.getItem('token');
        if (token == null) {
            return Promise.reject("No Auth");
        }
        let requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);
        requestHeaders.append("Access-Control-Allow-Origin", "*");
        requestHeaders.set('Content-Type', 'application/json');
        return fetch(url, {headers: requestHeaders})
            .then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            )
            .then(({status, statusText, headers, body}) => {
                if (status < 200 || status >= 300) {
                    localStorage.removeItem('token');
                    return Promise.reject("No Auth");
                }
                let json;
                try {
                    json = JSON.parse(body);
                    this.setState({
                        data: json
                    })
                } catch (e) {
                }

            });
    };

    loadMessages = () => {
        let url = `${SERVER_IP}/messages/get`;
        const token = localStorage.getItem('token');
        if (token == null) {
            return Promise.reject("No Auth");
        }
        let requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);
        requestHeaders.append("Access-Control-Allow-Origin", "*");
        requestHeaders.set('Content-Type', 'application/json');
        return fetch(url, {headers: requestHeaders})
            .then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            )
            .then(({status, statusText, headers, body}) => {
                if (status < 200 || status >= 300) {
                    localStorage.removeItem('token');
                    return Promise.reject("No Auth");
                }
                let json;
                try {
                    json = JSON.parse(body);
                    this.setState({
                        notifications: json.content
                    })
                } catch (e) {
                }

            });
    };

    render() {
        return (
            <div>
                <div style={styles.flex}>
                    <div style={styles.rightCol}>
                        <AdminMessage callback={this.loadMessages.bind(this)}/>
                    </div>
                    <div style={styles.leftCol}>
                    <MessagesList notifications={this.state.notifications}/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <UserChart android={this.state.data.androidUsers} ios={this.state.data.iosUsers}/>
                    </div>
                    <div style={styles.rightCol}>
                        <UserMonthChart users={this.state.data.users}
                                        monthlyUsers={this.state.data.monthlyUsers}
                                        weeklyUsers={this.state.data.weeklyUsers}/>
                    </div>
                </div>

            </div>
        )
            ;
    }
}
