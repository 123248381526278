import React from 'react';
import {ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin';

import ImageView from "../common/ImageView";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import {Button} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

export const ShowProviderCar = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={"General"}>
                <TextField source={"number"}/>
                <TextField source={"model"}/>
                <TextField source={"color"}/>
                <ReferenceField reference={"vehicles"} source={"vehicleId"}>
                    <TextField source={"name"}/>
                </ReferenceField>
                <TextField source={"insurance"}/>
                <TextField source={"personalId"} label={"Personal ID"}/>
            </Tab>
            <Tab label={"Images"}>
                <DocumentsView/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const CarImagesView = ({record}) => (
    <div style={{display: 'flex'}}>
        <ImageView title={"Car Image"} image={record.image}/>
        <ImageView title={"License Image"} image={record.imageLicense}/>
        <ImageView title={"Authorization Image"} image={record.imageAuthorization}/>
        <ImageView image={record.insurance} title={"Insurance Image"}/>

    </div>
);
const style = {
    flex: {display: 'flex'},
};

class DocumentsView extends React.Component {
    state = {
        open: false,
        message: "",
    };

    render() {
        const {record} = this.props;

        return (
            <div>
                <div style={style.flex}>
                    <CarImagesView record={record}/>
                </div>
                {!record.verified && <Button style={{margin: '1em'}} variant={"contained"} color={"secondary"}
                                             disabled={record.verified}
                                             onClick={() => {
                                                 fetchJson(`${SERVER_IP}/cars/verify/${record.id}`,
                                                     {method: "PUT"}).then(() => {
                                                     this.setState({
                                                         open: true,
                                                         message: "Car verified",
                                                         verified: true
                                                     })
                                                 }, () => {
                                                     this.setState({open: true, message: "Unknown error occurred"})
                                                 });

                                             }}>
                    {"Verify"}
                </Button>}
                <Snackbar autoHideDuration={5000}
                          message={this.state.message}
                          open={this.state.open}
                          onClose={() => {
                              this.setState({open: false})
                          }}/>
            </div>
        );
    }

}