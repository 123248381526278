import React from "react";
import Icon from "@material-ui/icons/AccountBalance";
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextInput
} from "react-admin";

export const BankAccountIcon = Icon;
export const BankAccountCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const BankAccountEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);
export const BankAccountList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <ChipField source={"bankName"}/>
            <ChipField source={"bankCode"}/>
            <ChipField source={"iban"}/>
            <ChipField source={"accountName"}/>
            <ChipField source={"accountNumber"}/>
            <BooleanField source={"enabled"}/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const BankAccountShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ChipField source={"bankName"}/>
            <ChipField source={"bankCode"}/>
            <ChipField source={"IBAN"}/>
            <ChipField source={"accountName"}/>
            <ChipField source={"accountNumber"}/>
            <BooleanField source={"enabled"}/>
        </SimpleShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <SimpleForm {...props} redirect={"list"}>
        <TextInput source={"bankName"} validate={required()}/>
        <TextInput source={"bankCode"} validate={required()}/>
        <TextInput source={"iban"} validate={required()}/>
        <TextInput source={"accountName"} validate={required()}/>
        <TextInput source={"accountNumber"} validate={required()}/>
        <BooleanInput source={"enabled"}/>
    </SimpleForm>
);

export const BankAccountTranslations = {
    en: {
        accounts: {
            name: "Bank Accounts",
            fields: {
                bankName: "Bank Name",
                bankCode: "Bank Code",
                iban: "IBAN",
                accountName: "Account Name",
                accountNumber: "Account Number",
                enabled: "Enabled"
            }
        },
    },
    ar: {
        accounts: {
            name: "Bank Accounts",
            fields: {
                bankName: "Bank Name",
                bankCode: "Bank Code",
                iban: "IBAN",
                accountName: "Account Name",
                accountNumber: "Account Number",
                enabled: "Enabled"
            }
        },
    },
};