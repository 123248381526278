import React from 'react';
import Icon from '@material-ui/icons/LocationCity';
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    FunctionField,
    List,
    maxLength,
    maxValue,
    minLength,
    minValue,
    NumberField,
    NumberInput,
    required,
    Show,
    ShowButton,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import {Field} from 'react-final-form';
import MyMap from "../common/MyMapArray";

export const CityIcon = Icon;
const CityFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn/>
    </Filter>
);
export const CityList = props => (
    <List {...props} bulkActionButtons={false} filters={<CityFilter/>}>
        <Datagrid rowClick="show">
            <TextField source="name"/>
            <TextField source="description"/>
            <NumberField source={"area"}/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const CreateCity = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const EditCity = (props) => (
    <Edit {...props} >
        <CreateOrEdit/>
    </Edit>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={"common.general"}>
            <TextInput source={"name"} validate={[required(), minLength(3), maxLength(100)]}/>
            <NumberInput source={"area"}
                         validate={[required(), minValue(10), maxValue(10000000)]}/>
            <TextInput source={"description"} validate={[required(), minLength(5)]}/>
        </FormTab>
        <FormTab label={"common.location"}>
            <Field name={"location"}
                   source={"location"}
                   editable
                   component={MyMap}
            />
        </FormTab>
    </TabbedForm>
);

export const ShowCity = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <FormTab label={"common.general"}>
                <TextField source={"name"}/>
                <TextField source={"description"}/>
                <NumberField source={"area"}/>
                <FunctionField label={"Time"} render={record => <span>{formatTime(record.time)}</span>}/>
            </FormTab>
            <FormTab label={"common.location"}>
                <MyMap source={"location"}/>
            </FormTab>

        </TabbedShowLayout>
    </Show>
);

function formatTime(time) {
    if (time === 0) {
        return "NA";
    } else {
        let date = new Date(time);
        return `${date.toDateString()} ${date.toLocaleTimeString()}`;
    }
}


