import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {translate} from 'react-admin';

class SendNotificationView extends React.Component {
    state = {};

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false});
    };
    handleChange = name => (event) => {
        if (name === 'title') {
            this.setState({errorTitle: false, title: event.target.value});
        } else if (name === 'body') {
            this.setState({errorBody: false, body: event.target.value});
        }
    };

    handleSend = () => {
        const {title, body} = this.state;
        if (title === undefined || title.length < 2) {
            this.setState({errorTitle: true});
            return;
        } else if (body === undefined || body.length < 5) {
            this.setState({errorBody: true});
            return;
        }
        const {record} = this.props;
        if (record === undefined) {
            return
        }
        this.setState({loading: true});
        let message = {
            subject: title,
            body: body,
        };
        let path = this.props.basePath;
        fetchJson(`${SERVER_IP}/${path}/send/${record.id}`,
            {body: JSON.stringify(message), method: "POST"})
            .then(() => {
                this.setState({loading: false, title: "", body: ""});
                this.props.onResponse(this.props.translate("users.message.sent"), false);
            }, () => {
                this.setState({loading: false});
                this.props.onResponse(this.props.translate("users.message.failed"), false);
            })

    };

    render() {
        const {loading, title, body, errorTitle, errorBody} = this.state;
        const {translate, open} = this.props;
        return (
            <Dialog open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate("users.message.sendMessage")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("users.message.writeMessage")}
                    </DialogContentText>
                    <TextField
                        style={{marginTop: '1em', marginBottom: '1em'}}
                        error={errorTitle}
                        id={"title"}
                        label={translate("users.message.subject")}
                        value={title}
                        disabled={loading}
                        onChange={this.handleChange('title')}
                        required/>
                    <TextField
                        error={errorBody}
                        style={{marginBottom: '1em'}}
                        id={"body"}
                        label={translate("users.message.body")}
                        value={body}
                        fullWidth
                        required
                        disabled={loading}
                        onChange={this.handleChange('body')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                this.props.onResponse(null, false);
                            }}>
                        {translate("users.message.cancel")}
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={this.handleSend}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        <SendIcon/>{translate("users.message.send")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const UserMessageTranslations = {
    en: {
        users: {
            message: {
                send: "Send",
                sendMessage: "Send Message",
                cancel: "Cancel",
                writeMessage: "Write the title and body of message. Message will be deliver immediately",
                body: "Body",
                subject: "Subject",
                sent: "Message sent successfully",
                failed: "Failed to send message",
            }
        }
    },
    ar: {
        users: {
            message: {
                send: "Send",
                sendMessage: "Send Message",
                cancel: "Cancel",
                writeMessage: "Write the title and body of message. Message will be deliver immediately",
                body: "Body",
                subject: "Subject",
                sent: "Message sent successfully",
                failed: "Failed to send message",
            }
        }
    }
};

export default translate(SendNotificationView);