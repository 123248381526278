import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import React from "react";
import Menu from './Menu';

export const lightTheme = {
    palette: {
        secondary: {
            light: '#cdf276',
            main: '#9abf46',
            dark: '#698f10',
            contrastText: '#fff',
        },
        primary: {
            light: '#3f6cb2',
            main: '#749ae4',
            dark: '#004282',
            contrastText: '#fff',
        },
    },
};

const CustomLayout = props => (
    <Layout {...props}
            menu={Menu}/>
);
export default connect(
    state => ({
        theme: lightTheme,
    }),
    {}
)(CustomLayout);