import {AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import {SERVER_IP} from "../index";

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        let body = {
            username: username,
            password: password
        };
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        const request = new Request(SERVER_IP + '/auth/auth', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: headers
        });

        request.headers.append("Content-Type", "application/json");
        request.headers.set("Content-Type", "application/json");

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({token}) => {
                //check role
                let headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Authorization","Bearer "+token);
                const req = new Request(SERVER_IP + "/auth/checkRole/" + username, {
                    method: 'POST',
                    headers: headers
                });
                return fetch(req)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        } else {
                            localStorage.setItem('token', token);
                            return Promise.resolve();
                        }
                    });
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const {status} = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject("unknown");
        }
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject("unknown");
    }

    return Promise.reject('Unknown method');
}