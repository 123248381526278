import React from "react";
import Icon from '@material-ui/icons/Receipt';
import {ChipField, Datagrid, Filter, List, SelectInput} from 'react-admin';
import {TimeField} from "../common/TimeField";
import {ProviderServiceReferenceField, serviceTypes} from "../util/ProviderPathUtil";
import {MoneyField} from "../common/MoneyField";
import {locale} from "../App";

export const ServiceReceiptIcon = Icon;

const Filters = props => (
    <Filter {...props}>
        <SelectInput source={"serviceType"}
                     optionText={choice => choice[locale()]}
                     choices={serviceTypes}/>
    </Filter>
);
export const ServiceReceiptList = (props) => (
    <List {...props} bulkActionButtons={false} filters={<Filters/>}>
        <Datagrid>
            <TimeField source={"time"}/>
            <ChipField source={"serviceType"}/>
            <ProviderServiceReferenceField
                source={"providerId"} fieldName={'serviceType'}/>
            <MoneyField source={"amount"}/>
        </Datagrid>
    </List>
);

export const ServiceReceiptTranslations = {
    en: {
        serviceReceipts: {
            name: "Service Receipts",
            fields: {
                time: "Time",
                serviceType: "Service Type",
                providerId: "Provider",
            }
        }
    },
    ar: {
        serviceReceipts: {
            name: "Service Receipts",
            fields: {
                time: "Time",
                serviceType: "Service Type",
                providerId: "Provider",
            }
        }
    }
};
