import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {DashboardMenuItem, MenuItemLink, Responsive, translate, WithPermissions} from 'react-admin';
import {withRouter} from 'react-router-dom';
import {UserIcon} from "../users/Users";
import {TranslationIcon} from "../translations/Translations";
import {VehicleCompanyIcon} from "../vehicle/Company";
import {VehicleIcon} from "../vehicle/Vehicle";
import {SettingIcon} from "../settings/Settings";
import {CityIcon} from "../cities/City";
import MovePersonIcon from "@material-ui/icons/LocalTaxi";
import GasIcon from "@material-ui/icons/LocalGasStation";
import FoodIcon from "@material-ui/icons/Restaurant";
import RepairIcon from "@material-ui/icons/Build";
import GroceryIcon from "@material-ui/icons/LocalGroceryStore";
import WaterIcon from "@material-ui/icons/Opacity";
import GoodsIcon from "@material-ui/icons/ShoppingBasket";
import CharityIcon from "@material-ui/icons/DeviceHub";
import {ReportIcon} from "../ComplainReport";
import SubMenu from "./SubMenu";
import PropTypes from 'prop-types';
import {BankAccountIcon} from "../accounts/BankAccount";
import {ServiceReceiptIcon} from "../accounts/ServiceReceipt";
import ContactUsIcon from '@material-ui/icons/ContactSupport';
import NotificationIcon from '@material-ui/icons/Notifications';

const providers = [
    {name: 'movePersonProviders', icon: <MovePersonIcon/>},
    {name: 'goodsProviders', icon: <GoodsIcon/>},
    {name: 'groceryProviders', icon: <GroceryIcon/>},
    {name: 'foodProviders', icon: <FoodIcon/>},
    {name: 'charityProviders', icon: <CharityIcon/>},
    {name: 'waterProviders', icon: <WaterIcon/>},
    {name: 'repairProviders', icon: <RepairIcon/>},
    {name: 'gasProviders', icon: <GasIcon/>},
];
const services = [
    {name: 'movePerson/service', icon: <MovePersonIcon/>},
    {name: 'goods/service', icon: <GoodsIcon/>},
    {name: 'repair/service', icon: <RepairIcon/>},
];


const companies = [
    {name: 'vehicles/companies', icon: <VehicleCompanyIcon/>},
    {name: 'vehicles', icon: <VehicleIcon/>},
];

const accounts = [
    {name: "accounts", icon: <BankAccountIcon/>},
    {name: 'serviceReceipts', icon: <ServiceReceiptIcon/>},
    {name: 'commissionReceipts', icon: <ServiceReceiptIcon/>},
];

const items = [
    {label: "common.menuVehicles", key: "companies", icon: <VehicleIcon/>, items: companies},
    {name: 'cities', icon: <CityIcon/>},
    {label: 'common.services', icon: <MovePersonIcon/>, items: services, key: 'services'},
    {name: 'users', icon: <UserIcon/>},
    {label: "common.menuProviders", key: "providers", icon: <UserIcon/>, items: providers},
    {label: 'common.accounts', key: "accounts", icon: <BankAccountIcon/>, items: accounts},
    {name: 'complainReports', icon: <ReportIcon/>},
    {name: 'screens', icon: <TranslationIcon/>},
    {name: 'settings', icon: <SettingIcon/>},
    {name: 'contactUs', icon: <ContactUsIcon/>},
    {name: 'messages', icon: <NotificationIcon/>}
];

class Menu extends React.Component {
    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };
    state = {
        providers: false,
        companies: false,
    };

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        const {onMenuClick, logout, translate} = this.props;
        return <div>
            <DashboardMenuItem onClick={onMenuClick}/>
            {items.map(item => {
                if (item.items !== undefined) {
                    return <SubMenu isOpen={this.state[item.key]}
                                    key={item.key}
                                    icon={item.icon}
                                    sidebarIsOpen={true}
                                    handleToggle={() => this.handleToggle(item.key)}
                                    name={item.label}>
                        {item.items.map(subItem => (
                            <MenuItemLink
                                key={subItem.name}
                                to={`/${subItem.name}`}
                                primaryText={translate(`resources.${subItem.name}.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={subItem.icon}
                                onClick={onMenuClick}
                            />
                        ))}
                    </SubMenu>
                }
                return <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={translate(`resources.${item.name}.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            })}
            <Responsive
                small={logout}
                medium={null}
            />
        </div>
    }
}

const mapStateToProps = state => {
    return {
        theme: state.theme,
    }
};

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);
const EnhancedMenu = enhance(Menu);
const PermissionMenu = (props) => (
    <WithPermissions render={({permissions}) => <EnhancedMenu {...props}
                                                              permissions={permissions}/>}
                     {...props}/>
);
export default PermissionMenu;
