import React from 'react';
import {
    BooleanField,
    Datagrid,
    ReferenceManyField,
    Show,
    ShowButton,
    Tab,
    TabbedShowLayout,
    TextField,
    translate
} from 'react-admin';
import {UserFeedback} from "./UserFeedback";
import {ShowUser, ShowUserServices} from "./ShowUser";
import ImageView from "../common/ImageView";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import {Button, CircularProgress} from "@material-ui/core";
import {CarImagesView} from "./Car";
import Snackbar from "@material-ui/core/Snackbar";
import UserActions from "./UserActions";
import {TimeField} from "../common/TimeField";

const ShowTitle = ({record}) => (<span>{record.name}</span>);
export const ShowProvider = (props) => (
    <Show {...props} title={<ShowTitle/>} actions={<UserActions/>}>
        <TabbedShowLayout>
            <ShowUser/>
            <Tab label={"common.reviews"}>
                <UserFeedback/>
            </Tab>
            <ShowUserServices usertype={"providerId"}/>
            <Tab label={"common.documents"}>
                <TranslatedDocumentView/>
            </Tab>
            <Tab label={"resources.vehicles.name"}>
                <ReferenceManyField target={"userId"} reference={"cars"} addLabel={false}>
                    <Datagrid>
                        <TextField source={"number"}/>
                        <TextField source={"model"}/>
                        <TextField source={"color"}/>
                        <BooleanField source={'verified'}/>
                        <ShowButton/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={'resources.users.messages'}>
                <ReferenceManyField target={'userId'} reference={'messages'} addLabel={false}>
                    <Datagrid>
                        <TextField source={'subject'}/>
                        <TextField source={'body'}/>
                        <TimeField source={'time'}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const style = {
    flex: {display: 'flex'},
};

class DocumentsView extends React.Component {
    state = {
        open: false,
        message: "",
    };

    render() {
        const {record, translate} = this.props;

        return (
            <div>
                <div style={style.flex}>
                    <ImageView title={translate("documents.driverNumber")} image={record.imageDriverNumber}/>
                    {record !== undefined && record.authority === "ROLE_MOVE_PROVIDER" &&
                    <CarDocumentsView carId={record.activeCar}/>}

                </div>
                <Button style={{margin: '1em'}} variant={"contained"} color={"secondary"}
                        disabled={this.state.adminVerified}
                        onClick={() => {
                            fetchJson(`${SERVER_IP}/providers/${getProviderPath(record.authority)}/verifyAdmin/${record.id}`,
                                {method: "PUT"}).then(() => {
                                this.setState({
                                    open: true,
                                    message: translate("documents.userVerified"),
                                    adminVerified: true
                                })
                            }, () => {
                                this.setState({open: true, message: "Unknown error occurred"})
                            });

                        }}>
                    {translate("documents.verify")}
                </Button>
                <Snackbar autoHideDuration={5000}
                          message={this.state.message}
                          open={this.state.open}
                          onClose={() => {
                              this.setState({open: false})
                          }}/>
            </div>
        );
    }

}

const getProviderPath = (authority) => {
    switch (authority) {
        case "ROLE_GAS_PROVIDER":
            return "gas";
        case "ROLE_FOOD_PROVIDER":
            return "food";
        case "ROLE_WATER_PROVIDER":
            return "water";
        case "ROLE_REPAIR_PROVIDER":
            return "repair";
        case "ROLE_CHARITY_PROVIDER":
            return "charity";
        case "ROLE_GROCERY_PROVIDER":
            return "grocery";
        case "ROLE_MOVE_PROVIDER":
            return "movePerson";
        case "ROLE_GOODS_PROVIDER":
            return "goods";
        default:
            return "";
    }
};

const TranslatedDocumentView = translate(DocumentsView);

class CarDocumentsView
    extends React.Component {
    state = {
        car: null,
    };

    componentDidMount() {
        fetchJson(`${SERVER_IP}/cars/get/${this.props.carId}`).then(({json}) => {
            this.setState({car: json})
        });
    }

    render() {
        const {car} = this.state;
        if (car == null) {
            return <div>
                <CircularProgress/>
            </div>
        }
        return (
            <CarImagesView record={car}/>
        );
    }

}