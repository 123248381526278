import React from "react"
import {compose, withProps} from "recompose"
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps"

const url = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDERFfNsfd8P2uz2c01ky9JsifDTqBZEuU&libraries=geometry`;
const MyMapComponent = compose(
    withProps({
        googleMapURL: url,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    let position = props.position;
    if (position === undefined || position.lat === undefined) {
        position = {lat: 21.381, lng: 39.8579};
    }
    return <GoogleMap
        defaultZoom={13}
        center={position}
        onClick={props.onClick}
    >
        {props.isMarkerShown && <Marker position={props.position} onClick={props.onMarkerClick}/>}
    </GoogleMap>
});


class MyFancyComponent extends React.PureComponent {
    state = {
        isMarkerShown: false,
        position: null
    };

    componentDidMount() {
        console.log(this.props);
        if (this.props.editable) {
            if (this.props.input != null) {
                let pos = {lat: this.props.input.value[1], lng: this.props.input.value[0]};
                this.setState({position: pos})
            }
        } else if (this.props.record.location != null) {
            let pos = {lat: this.props.record.location[1], lng: this.props.record.location[0]};
            this.setState({position: pos})
        }
        this.delayedShowMarker()
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({isMarkerShown: true})
        }, 3000)
    };

    handleMarkerClick = () => {
        this.setState({isMarkerShown: false});
        this.delayedShowMarker()
    };
    handleMapClick = (event) => {
        if (this.props.editable) {
            let location = [event.latLng.lng(), event.latLng.lat()];
            this.props.input.onChange(location);
            this.setState({position: event.latLng});
        }
    };

    render() {
        return (
            <MyMapComponent
                isMarkerShown={true}
                name={"location"}
                onMarkerClick={this.handleMarkerClick}
                onClick={this.handleMapClick}
                position={this.state.position}
            />
        )
    }
}

export default MyFancyComponent;