import React from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField
} from 'react-admin'
import {repairStatus, repairTypes, status, types} from "./Status";

export const userText = user => user !== undefined && user.name !== undefined ? `${user.name} | ${user.username}` : '';
const Filters = props => (
    <Filter {...props}>
        <ReferenceInput source={"userId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'users'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <ReferenceInput source={"providerId"}
                        allowEmpty
                        filterToQuery={text => ({username: text})}
                        reference={'goodsProviders'}>
            <AutocompleteInput optionText={userText}/>
        </ReferenceInput>
        <SelectInput source={'type'}
                     alwaysOn
                     choices={types}/>
        <SelectInput source={'status'}
                     alwaysOn
                     choices={status}/>
        <SelectInput source={'repairStatus'}
                     alwaysOn
                     choices={repairStatus}/>
        <SelectInput source={'repairType'}
                     choices={repairTypes}
                     alwaysOn/>

    </Filter>
)
export const RepairServicesList = props => (
    <List {...props} sort={{field: 'requestTime', order: 'DESC'}}
          exporter={false}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
            <DateField source="requestTime" showTime/>
            <ReferenceField source="userId" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="providerId" reference="repairProviders">
                <TextField source="name"/>
            </ReferenceField>
            <NumberField source="price"/>
            <TextField source="repairType"/>
            <TextField source="noOfRepairs"/>
        </Datagrid>
    </List>
);

export const RepairTranslations = {
    en: {
        "repair/service": {
            name: "Repair Services",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                repairType: "Repair Type",
                noOfRepair: "Repair Numbers",
            }
        },
    },
    ar: {
        "repair/service": {
            name: "Repair Services",
            fields: {
                id: "#",
                requestTime: "Time",
                userId: "User",
                providerId: "Provider",
                status: "Status",
                price: "Price",
                repairType: "Repair Type",
                noOfRepair: "Repair Numbers",
            }
        },
    },
}