import React from "react";
import Icon from '@material-ui/icons/Receipt';
import {
    ChipField,
    Create,
    Datagrid,
    Filter,
    FormDataConsumer,
    List,
    minLength,
    minValue,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextInput,
    translate
} from 'react-admin';
import {TimeField} from "../common/TimeField";
import {ProviderReferenceInput, ProviderServiceReferenceField, serviceTypes} from "../util/ProviderPathUtil";
import {MoneyField} from "../common/MoneyField";
import {locale} from "../App";
import PaymentActions from "./PaymentActions";

export const CommissionReceiptIcon = Icon;

const statuses = [
    {id: "PENDING", name: 'resources.commissionReceipts.status.pending'},
    {id: "APPROVED", name: 'resources.commissionReceipts.status.approved'},
    {id: "REJECTED", name: 'resources.commissionReceipts.status.rejected'}
];
const Filters = props => (
    <Filter {...props}>
        <SelectInput source={"serviceType"}
                     optionText={choice => choice[locale()]}
                     choices={serviceTypes}/>
    </Filter>
);
export const CommissionReceiptList = (props) => (
    <List {...props} bulkActionButtons={false} filters={<Filters/>}>
        <Datagrid>
            <ChipField source={"senderName"}/>
            <ChipField source={"senderAccount"}/>
            <TimeField source={"time"}/>
            <ReferenceField source={"accountId"}
                            allowEmpty
                            link={false}
                            reference={"accounts"}>
                <ChipField source={'accountNumber'}/>
            </ReferenceField>
            <ProviderServiceReferenceField
                source={"providerId"} fieldName={'serviceType'}/>
            <MoneyField source={"amount"}/>
            <ReceiptStatusField source={"status"}/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const ShowCommissionReceipt = (props) => (
    <Show {...props} actions={<PaymentActions/>}>
        <SimpleShowLayout>
            <ChipField source={"senderName"}/>
            <ChipField source={"senderAccount"}/>
            <TimeField source={"time"}/>
            <ReferenceField source={"accountId"}
                            allowEmpty
                            link={false}
                            reference={"accounts"}>
                <ChipField source={'accountNumber'}/>
            </ReferenceField>
            <ProviderServiceReferenceField
                source={"providerId"} fieldName={'serviceType'}/>
            <MoneyField source={"amount"}/>
        </SimpleShowLayout>
    </Show>
);

export const CommissionReceiptCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={'senderName'} validate={[required(), minLength(3)]}/>
            <TextInput source={'senderAccount'} validate={[required(), minLength(5)]}/>
            <NumberInput source={'amount'} validate={[required(), minValue(5)]}/>
            <ReferenceInput source={'accountId'}
                            validate={required()}
                            reference={'accounts'}>
                <SelectInput optionText={record => <span>{`${record.accountName}/${record.accountNumber}`}</span>}/>
            </ReferenceInput>
            <SelectInput choices={serviceTypes}
                         validate={required()}
                         optionText={choice => choice[locale()]}
                         source={'serviceType'}/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.serviceType &&
                    <ProviderReferenceInput
                        key={formData.serviceType}
                        source="providerId"
                        formData={formData}
                        validate={required()}
                        serviceType={formData.serviceType}
                        {...rest}
                    />
                }
            </FormDataConsumer>
            <SelectInput source={'status'} choices={statuses}/>
        </SimpleForm>
    </Create>
);
const ReceiptStatusField = translate(({source, record, translate}) => {
    if (record === undefined || record[source] === undefined) {
        return ""
    }
    switch (record[source]) {
        case "PENDING":
            return translate('resources.commissionReceipts.status.pending');
        case "APPROVED":
            return translate('resources.commissionReceipts.status.approved');
        default:
            return translate('resources.commissionReceipts.status.rejected');

    }
});
export const CommissionReceiptTranslations = {
    en: {
        commissionReceipts: {
            name: "Commission Receipts",
            fields: {
                time: "Time",
                serviceType: "Service Type",
                providerId: "Provider",
                senderName: "Sender Name",
                senderAccount: "Sender account",
                accountId: "Account",
                status: "Status",
            },
            status: {
                pending: "Pending",
                approved: "Approved",
                rejected: "Rejected",
            }
        }
    },
    ar: {
        commissionReceipts: {
            name: "Commission Receipts",
            fields: {
                time: "Time",
                serviceType: "Service Type",
                providerId: "Provider",
                senderName: "Sender Name",
                senderAccount: "Sender account",
                accountId: "Account",
                status: "Status"
            },
            status: {
                pending: "Pending",
                approved: "Approved",
                rejected: "Rejected",
            }
        }
    }
};
