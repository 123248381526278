import React from "react";
import {List, Datagrid, TextField, DeleteWithConfirmButton} from 'react-admin';
import {TimeField} from "../common/TimeField";

export const NotificationList = props => (
    <List {...props} sort={{field: 'time', order: "DESC"}}>
        <Datagrid>
            <TextField source={'subject'}/>
            <TextField source={"body"}/>
            <TimeField source={'time'}/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const NotificationTranslations = {
    en: {
        messages: {
            name: "Notifications",
            fields: {
                subject: "Tittle",
                body: "Text",
                time:"Time",
            }
        }
    },
    ar: {
        messages: {
            name: "Notifications",
            fields: {
                subject: "Tittle",
                body: "Text",
                time:"Time",
            }
        }
    }
}