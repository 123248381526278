import React from 'react';
import {Cell, Pie, PieChart, Tooltip} from 'recharts'
import {translate} from 'react-admin';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from "@material-ui/core/Avatar";
import UserIcon from "@material-ui/icons/VerifiedUser";

const styles = {
    card: {borderLeft: 'solid 4px #2196F3', flex: 1, margin: '1em', height: 290},
};

class UserPlatformChart extends React.Component {
    state = {
        data: [{name: "Android", value: 60}, {name: "iOS", value: 90}]
    };

    componentDidMount() {
        let data = [{name: "Android", value: this.props.android}, {name: "iOS", value: this.props.ios}];
        this.setState({data: data})
    }

    componentWillReceiveProps(nextProps, context) {
        let data = [{name: "Android", value: nextProps.android, color: "#A4C639"},
            {name: "iOS", value: nextProps.ios, color: "#147efb"}];
        this.setState({data: data})
    }

    render() {
        return (
            <Card style={styles.card}>
                <CardHeader avatar={<Avatar style={{color: "#4caf40", background: "#DADADA"}}>
                    <UserIcon/>
                </Avatar>}
                            title={<h3>Users by platform</h3>}
                />
                <CardContent>
                    <PieChart width={400} height={200}>
                        <Pie data={this.state.data} isAnimationActive={true}
                             outerRadius={60}
                             cy={70}
                             label
                        >
                            {this.state.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color}/>
                            ))
                            }
                        </Pie>
                        <Tooltip/>
                    </PieChart>
                </CardContent>
            </Card>
        )
    }
}

export default translate(UserPlatformChart);